import axios from "axios";
import { customURLGen, can_add } from '../ReusableComponents/urlGenerator'

export default{
    getClientsList,
    getReferralListing,
    getNotesListing,
    getFollowupListing,
    getStaleRecordsListing,
    getopenFormListing,
    getUserListing,
    addUser,
    editUser,
    getRolesList,
    getUserSearchListing,
    getPeerListing,
    publishClients,
    reAssignClients
}
// var jsonData = { 'apikey': sessionStorage.getItem('__TOKEN__') };

  function getClientsList(jsonData){
      // var jsonData = { 'apikey': sessionStorage.getItem('__TOKEN__') };
      jsonData.filters = {};

      if (sessionStorage.getItem('StatusRequestClientsListing') === 'Status_requested_status') {
        if (sessionStorage.getItem('client_search_filter') === 'requested_status') {
          jsonData.filters['client_status'] = ['Activate', 'Inactivate'];
        }
      }

      if (sessionStorage.getItem('selected_organization') != null || sessionStorage.getItem('selected_organization') != undefined || sessionStorage.getItem('selected_organization') != '') {
        jsonData.filters['organization'] = sessionStorage.getItem('selected_organization');
      }
      var datasend = JSON.stringify(jsonData);
      return axios.post(customURLGen("clientList"), datasend)
        .then((res) => {
          const returndata = res.data; // get the data array instead of object
          if (returndata.success && res.status == 200) {
            return Promise.resolve(returndata);
          }
          else if ("error_code" in returndata) {
            console.log("Session Out");
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            sessionStorage.setItem('error_msg', returndata.msg);
          }

        })
        .catch(err => { // log request error and prevent access to undefined state
          return err;
        })
  }

  async function getReferralListing(requestData){
    return axios.post(global.restApiURL + 'customRest/getReferralsList', JSON.stringify(requestData))
        .then(res => {
          if (res.data.success && res.status==200) {
            // const response = res.data.data.elements;
            setTimeout(function () {
              sessionStorage.setItem('referralsListData_length', res.data.data.length)
            }, 50)
            if(res.data.success && res.status == 200){
              return Promise.resolve(res.data.data);
            }
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
            return res.data.data;
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
  }

  async function getNotesListing(requestData){
    return axios.post(global.restApiURL + 'customRest/getNotesForDashboard', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          // const response = res.data.data.elements;
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })
  }

  async function getFollowupListing(requestData){
    return axios.post(global.restApiURL + 'customRest/getFollowupsForDashboard', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function getStaleRecordsListing(requestData){
    return axios.post(global.restApiURL + 'customRest/getStaleRecordForDashboard', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function getopenFormListing(requestData){
    return axios.post(global.restApiURL + 'customRest/getOpenFormsRecords', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }
  async function getUserListing(requestData){
    return axios.post(global.restApiURL + 'customRest/getUserList', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function addUser(requestData){
    return axios.post(global.restApiURL + 'customRest/addUser', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function editUser(requestData){
    return axios.post(global.restApiURL + 'customRest/editUser', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function getRolesList(requestData){
    return axios.post(global.restApiURL + 'customRest/getRolesList', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }
  async function getUserSearchListing(requestData){
    return axios.post(global.restApiURL + 'customRest/getSearchResult', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function getPeerListing(requestData) {
    return axios.post(global.restApiURL + 'customRest/getPeerSupportSpecialist', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status == 200) {
          //  if(res.data.data.length>0){
          return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function publishClients(requestData){
    return axios.post(global.restApiURL + 'customRest/publishClients', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }

  async function reAssignClients(requestData){
    return axios.post(global.restApiURL + 'customRest/reAssignClient', JSON.stringify(requestData))
      .then(res => {
        if (res.data.success && res.status==200) {
          //  if(res.data.data.length>0){
              return Promise.resolve(res.data.data);
          //  }
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
          return res.data.data;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        console.error(err);
        return err;
      })
  }
