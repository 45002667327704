import React, { Component } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import services from '../../services';
import Topnav from '../Topnav';
import AdminSideNav from '../UserManagement/AdminSideNav';
import ClientListing from './clientListing';
import DateTime from 'react-datetime';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import $ from 'jquery';
import { customURLGen, can_add } from '../../ReusableComponents/urlGenerator'
import Footer from '../Footer';

const axios = require('axios').default;


export default class clientManagement extends Component {
    constructor(props) {
        super(props);
        this.dataSorting = this.dataSorting.bind(this);
        this.childRef = React.createRef();
        this.state = {
            emailSuggestions: [],
            namesSuggestions: [],
            clientList: [],
            search: "",
            userList: {},
            startDateintakedate: '',
            dateRange: '',
            key: '',
            listNext: 1,
            pageInfo: {
                pageString: "",
                pages: 0,
                records: 0
            },
        }
    }

    updateListNext = (pageNum) => {
        this.setState({
            listNext: pageNum
        })
    }

    async componentDidMount() {
        await this.getClientList();
        await this.getPeerSupportSpecialistList();
    }

    resetDateRange = () => {
        this.setState({ dateRange: '', key: Date.now() }); // Reset dateRange and force re-render with key
    } 

    resetSearchParams = async (event) => {
        await this.setState({ startDateintakedate: '', listNext: 1 });
        if (this.childRef != null) {
            this.childRef.current.resetLimit();
        }
        await $("#clientsearchform :input").val('');
        this.resetDateRange()
        this.getClientList();
        setTimeout(() => this.refs.email_typeahead.getInstance().clear(), 10);
        setTimeout(() => this.refs.name_typeahead.getInstance().clear(), 10);
    }

    handleChange = (date) => {
        this.setState({
            startDateintakedate: date,
        });
    };

    parseResponse = (promise) => {
        if (promise != true) {
            return promise.then(data => {
                return data;
            }).catch(err => {
                console.log(err);
            });
        }
    };

    CryptoJSAesDecrypt(passphrase, encrypted_json_string) {
        const CryptoJS = require("crypto-js");
        var obj_json = JSON.parse(encrypted_json_string);
        var encrypted = obj_json.ciphertext;
        var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
        var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
        var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    dataSorting = (name, rowData) => {
        let apiData = rowData.map((val) => {
            if (!name == "") {
                return { [name]: val[name] };
            }
        });
        let filteredData = apiData.filter(function (element) {
            return element[name] !== undefined && element[name] !== '';
        })
        let sortedData = filteredData.sort((a, b) => {
            if (a[name] < b[name]) {
                return -1;
            }
            if (a[name] > b[name]) {
                return 1;
            }
            return 0;
        });
        if (name == 'Full_name') {
            this.setState({ namesSuggestions: sortedData });
        }
        if (name == 'Primary_email') {
            this.setState({ emailSuggestions: sortedData });
        }
    }

    getClientList = async (param) => {
        debugger
        let requestData = {
            'apikey': sessionStorage.getItem('__TOKEN__'),
            // "is_published": true,
            "unpublished": 1,
            "page": 1,
            limit: 10,
            'filters': {
                "organization": sessionStorage.getItem('selected_organization')
            }
        }
        if (param != undefined && param != "" && param != null) {
            requestData.limit = param;
        }
        let clientListApi = await this.parseResponse(services.getClientsList(requestData));
        this.dataSorting('Primary_email', JSON.parse(this.CryptoJSAesDecrypt("tactionData", clientListApi.data)));
        this.dataSorting('Full_name', JSON.parse(this.CryptoJSAesDecrypt("tactionData", clientListApi.data)));
        this.setState({ clientList: JSON.parse(this.CryptoJSAesDecrypt("tactionData", clientListApi.data)), pageInfo: clientListApi.pageInfo });
    }

    getPeerSupportSpecialistList = async () => {
        let requestData = {
            'apikey': sessionStorage.getItem('__TOKEN__'),
            'organization': sessionStorage.getItem('selected_organization')
        }
        let userListApi = await this.parseResponse(services.getPeerListing(requestData));
        userListApi.map((user, index) => {
            if (user.active == "Inactive") {
                userListApi.splice(index, 1)
            }
        })
        this.setState({ userList: userListApi });
    }

    valueForSearch = () => {
        let decision = false;
        $("#clientsearchform").serializeArray().map((val) => {
            if (val.value != "") {
                decision = true;
            }
        });
        return decision;
    }

    getsearchresult = async (event) => {
        var jsonData = { 'apikey': sessionStorage.getItem('__TOKEN__'), "unpublished": 1 };
        if (isNaN(event)) {
            event.preventDefault();
        } else {
            if (event <= this.state.pageInfo.pages) {
                jsonData.page = event;
            }
        }
        jsonData.filters = {}
        if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
            //this.setState({showOrganizationModal : true});
            return true;
        } else {
            jsonData.filters['organization'] = sessionStorage.getItem('selected_organization');
        }
        var formData = $("#clientsearchform").serializeArray();
        if (this.state.startDateintakedate != "") {
            jsonData.filters['dob'] = this.state.startDateintakedate;
        }
        $.each(formData, function () {
            if (jsonData.filters[this.name]) {
                if (!jsonData.filters[this.name].push) {
                    jsonData.filters[this.name] = [jsonData.filters[this.name]];
                }
                if (this.value !== '')
                    jsonData.filters[this.name].push(this.value || '');
            }
            else {
                if (this.value !== '')
                    jsonData.filters[this.name] = this.value || '';
            }

        });
        if (formData[0].value == "" && formData[1].value == "" && formData[2].value == "" && formData[3].value == "" && formData[4].value == "" && formData[5].value == "") {
            if (typeof event == 'number') {
                this.setState({ listNext: event });
            } else {
                this.setState({ listNext: 1 });
            }
        }
        var json = JSON.stringify(jsonData);
        await axios.post(customURLGen("clientList"), json)
            .then(async res => {
                const returndata = res.data; // get the data array instead of object
                if (returndata.success && res.status == 200) {
                    await this.setState({
                        clientList: JSON.parse(this.CryptoJSAesDecrypt("tactionData", returndata.data)),
                        pageInfo: returndata.pageInfo,
                        search: true
                    });
                }
                else if ("error_code" in returndata) {
                    console.log("Session Out");
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(returndata.msg)
                }
            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });
        this.setState({ searchValue: null });
        sessionStorage.removeItem('searchValues');
    }

    handleDateOnBlur = (date) => {
        let startDateintakedateOnBlur;
        let dateDiff = moment().diff(moment(date), 'years');
        if (typeof date === 'string' && typeof date !== 'object' && date !== "") {
            startDateintakedateOnBlur = moment().subtract(14, 'years');
        }
        if (date === "" || date === "Invalid date") {
            startDateintakedateOnBlur = "";//typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment().subtract(14, 'years') : (typeof date == 'object' && date !== null ? moment(date._d).format('MM/DD/YYYY') : "");
        } else if (typeof date == 'object' && date !== null) {
            if (dateDiff < 14) {
                startDateintakedateOnBlur = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment().subtract(14, 'years') : (typeof date == 'object' && date !== null ? moment(date._d).subtract(14, 'years').format('MM/DD/YYYY') : "");
            } else {
                startDateintakedateOnBlur = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment().subtract(14, 'years') : (typeof date == 'object' && date !== null ? moment(date._d).format('MM/DD/YYYY') : "");
            }
        }
        this.setState({ startDateintakedate: startDateintakedateOnBlur === "" ? "" : moment(startDateintakedateOnBlur).format('MM/DD/YYYY') });
    }

    setDate = (event, picker) => {
        var startDate = this.convert(String(picker.startDate));
        var endDate = this.convert(String(picker.endDate));
        var dateRange = startDate + " - " + endDate;
        this.setState({
            dateRange: dateRange
        });
    }

    convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    stateSattlement = (a = [], b = "") => {
        this.setState({
            clientList: a,
            pageInfo: b,
        });
    }

    render() {

        return (
            <div className="hold-transition sidebar-mini text-sm">
                <div className="wrapper">
                    <Topnav />
                    <div className="content-wrapper">
                        <section class="content-header"></section>
                        <div className='row'>
                            <div className='col-md-2'>
                                <AdminSideNav />
                            </div>
                            <div className='col-md-10'>
                                <section className="content">
                                    <form method='post' id="clientsearchform" >
                                        <div className="card">
                                            <div className="card-header" >
                                                <h3 className="card-title">Search</h3>
                                            </div>
                                            <div id="searchdiv" className="card-body row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Client Name</label>
                                                        <Typeahead
                                                            ref='name_typeahead'
                                                            inputProps={{ name: 'name' }}
                                                            labelKey="Full_name"
                                                            options={this.state.namesSuggestions} // options={client_names}
                                                            placeholder="Client Name"
                                                            className="search"
                                                            id="name"
                                                            clearButton={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Client ID</label>
                                                        <input type="text" className="form-control" id="client_id" name="client_id" placeholder="Client ID" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Date of Birth (MM/DD/YYYY)</label>
                                                        <div className="input-group date">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="far fa-calendar-alt"></i>
                                                                </span>
                                                            </div>
                                                            <DateTime
                                                                onBlur={(date) => this.handleDateOnBlur(date)}
                                                                onChange={(date) => this.handleChange(date)}
                                                                inputProps={{ value: typeof this.state.startDateintakedate === 'string' ? this.state.startDateintakedate : moment(this.state.startDateintakedate).format('MM/DD/YYYY'), placeholder: 'Date of birth' }}
                                                                defaultValue={moment.utc(this.state.startDateintakedate).format('MM/DD/YYYY')}
                                                                dateFormat="MM/DD/YYYY"
                                                                timeFormat={false}
                                                                input={true}
                                                                id="dob" name="dob"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Intake Date</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="far fa-calendar-alt"></i>
                                                                </span>
                                                            </div>
                                                            <DateRangePicker onApply={this.setDate} key={this.state.key} locale={this.state.locale} className="">
                                                                <input className="form-control pull-right" id="intake_date" name="intake_date" placeholder="Intake Date" readOnly type="text" value={this.state.dateRange} style={{ backgroundColor: "transparent" }} />
                                                            </DateRangePicker>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Phone</label>
                                                        <input type="text" className="form-control" id="primary_phone" name="primary_phone" placeholder="Phone" data-inputmask="'mask': '(999) 999-9999'" data-mask />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <Typeahead
                                                            ref='email_typeahead'
                                                            inputProps={{ name: 'primary_email', id: 'primary_email' }}
                                                            labelKey="Primary_email"
                                                            options={this.state.emailSuggestions}//email_options
                                                            placeholder="Email"
                                                            className="search"
                                                            id="primary_email"
                                                            clearButton={true}
                                                        />
                                                        {this.state.email_error !== "" ? (<span className="text-danger">{this.state.email_error}</span>) : ""}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>Peer support specialist</label>
                                                        <select className='form-control' name='user_id'>
                                                            <option value="">Select</option>
                                                            {this.state.userList.length > 0 && this.state.userList.map(user => (
                                                                <option value={user.firstname + ' ' + user.lastname}>{user.firstname + ' ' + user.lastname}</option>
                                                            ))}
                                                        </select>
                                                        {this.state.peerSupportSpecialist_error !== "" ? (<span className="text-danger">{this.state.peerSupportSpecialist_error}</span>) : ""}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>&nbsp;</label><br />
                                                        <button type="button" onClick={this.resetSearchParams} className="btn btn-default float-sm-right clientSearchButton" > Reset</button>
                                                        <button type="submit" onClick={this.getsearchresult} className="btn btn-primary float-sm-right marginbtn clientSearchButton" > Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </section>
                                {this.state.userList.length > 0 && <ClientListing
                                    changePagination={this.updateListNext}
                                    dataSorting={this.dataSorting}
                                    clientList={this.state.clientList}
                                    getClientList={this.getClientList}
                                    wholeState={this.state}
                                    stateSattlement={this.stateSattlement}
                                    searchclients={this.getsearchresult}
                                    parseResponse={this.parseResponse}
                                    userList={this.state.userList}
                                    searchValue={this.valueForSearch}
                                    ref={this.childRef}
                                />
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
