import React, { useState, useEffect } from 'react';
import Topnav from './Topnav';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../global.js';
import Loader from './Loader';

const RequestSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    email: '',
    browser: '',
    browser_version_no: '',
    os: '',
    version_number: '',
    page_issue: '',
    page_issue_description: '',
  });

  const [emailError, setEmailError] = useState('');
  const [issueDescriptionErr, setissueDescriptionErr] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    const getBrowserInfo = () => {
      const userAgent = navigator.userAgent;
      let browser = 'Unknown';
      let browserVersion = 'Unknown';

      // Detect browser
      if (userAgent.indexOf('Firefox') > -1) {
        browser = 'Firefox';
        browserVersion = userAgent.split('Firefox/')[1];
      } else if (userAgent.indexOf('Chrome') > -1) {
        browser = 'Chrome';
        browserVersion = userAgent.split('Chrome/')[1];
      } else if (userAgent.indexOf('Safari') > -1) {
        browser = 'Safari';
        browserVersion = userAgent.split('Version/')[1];
      } else if (userAgent.indexOf('Edge') > -1) {
        browser = 'Edge';
        browserVersion = userAgent.split('Edge/')[1];
      }

      setFormData((prevData) => ({
        ...prevData,
        browser,
        browser_version_no: browserVersion,
      }));
    };

    
    

    getBrowserInfo();
  

    // Retrieve values from sessionStorage
    const updateFormData = () => {
      const loggedInUsername = sessionStorage.getItem('loggedinusername');
      const selectedOrganization = sessionStorage.getItem('selected_organization');
      const loggedInUseremail=sessionStorage.getItem('loggedinuseremail')

      setFormData((prevData) => ({
        ...prevData,
        name: loggedInUsername || '',
        organization: selectedOrganization || '',
        email: loggedInUseremail || '',
      }));
    };

    updateFormData();

    window.addEventListener('storage', updateFormData);

    return () => {
      window.removeEventListener('storage', updateFormData);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }
    }
    if(name === 'page_issue_description'){
      if(value == ''){
        setissueDescriptionErr('Description is mandatory field.');
      }else{
        setissueDescriptionErr('')
      }
    }
  };

  const handleAttachmentsChange = (e) => {
    const maxSize = 20e6;
    const allowedFileTypes = ["image/jpeg", "application/pdf", "image/png"];
    const files = Array.from(e.target.files);
    console.log("raj", files)
    const invalidFiles = [];
    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        invalidFiles.push(`File Name:${file.name}  Exceeds the maximum size of 20 MB.  Please select a file with size less than 20 MB.`);
        return false;
      }
      if (!allowedFileTypes.includes(file.type)) {
        invalidFiles.push(`Invalid file type for ${file.name}. Please select only jpg, png, or pdf files.`);
        return false;
      }
      return true;
    });
    if (invalidFiles.length > 0) {
      invalidFiles.forEach((message) => {
        toast.error(message);
      });
      return;
    }
    setSelectedFiles(validFiles);
    setFormData({ ...formData, attachment: validFiles });
  }

  const handleSave = async () => {
    if (!formData.email || emailError) {
      setEmailError('Email is mandatory and must be valid');
      return;
    }
    if ((formData.page_issue_description === "" && issueDescriptionErr !== "") || (formData.page_issue_description === "" && issueDescriptionErr == "")) {
      setissueDescriptionErr('Description is mandatory field.');
      return;
    }
    const payload = new FormData();
    function generateRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const randomNumber = generateRandomNumber(1000, 9999);
    const key = `cl-${randomNumber}`;
    payload.append('dataListBody', JSON.stringify({
      apikey: sessionStorage.getItem("__TOKEN__"), key,
      ...formData,
    }));
  
    if (Array.isArray(formData.attachment)) {
      formData.attachment.forEach((attachment, index) => {
        payload.append('attachment[]', attachment);
      });
    } else {
      console.error('Attachments must be an array.');
    }
    try {
      setisloading(true);
      const response = await axios.post(global.restApiURL + "customrest/sendSupportEmail", payload);
      if (response.status === 200) {
        const responseData = response.data;
        toast.success("Your request has been submitted.");
        const loggedInUsername = sessionStorage.getItem('loggedinusername');
        const selectedOrganization = sessionStorage.getItem('selected_organization');
        const loggedInUseremail = sessionStorage.getItem('loggedinuseremail');

        setFormData((prevData) => ({
          name: loggedInUsername || '',
          organization: selectedOrganization || '',
          email: loggedInUseremail || '',
          browser: prevData.browser, // <-- retain browser value
          browser_version_no: prevData.browser_version_no, // <-- retain browser_version_no value
          os: '',
          version_number: '',
          page_issue: '',
          page_issue_description: '',
        }));
        setisloading(false);
        setSelectedFiles([]);
        sessionStorage.setItem('success_msg', "Your request has been submitted.");
        // setTimeout(() => {
          window.location.href = global.domain + 'myclients';
        // }, 6000);
      } else {
        console.error('API request failed:', response.statusText);
      }
    }
    catch (error) {
      console.error('Error during API request:', error);
    }
  };


  return (
    <>
      <Topnav></Topnav>
      {isloading ? <Loader/> : ''}
      <div className="wrapper" >
        <div className="content-wrapper" >
          <section className="content">
            <form method="post" >
              <div className="card mx-auto" style={{ width: '70%', top: '10px', }}>
                <div className="card-header">
                  <h1 className="card-title3" style={{ color: '#89bc3b', fontWeight: 'bolder', height: "50px", fontSize: "30px" }}>Get Support</h1>
                </div>
                <div className="card-body" >
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" onChange={handleChange} value={formData.name} name="name" placeholder="Enter Your Name" readOnly/>
                  </div>
                  <div className="form-group">
                    <label>Organization </label>
                    <input type="ext" className="form-control" name="organization" onChange={handleChange} value={formData.organization} placeholder="Enter Your Organization" readOnly />
                  </div>
                  <div className="form-group">
                    <label>Email Address </label>
                    <input
                      type="email"
                      className={`form-control ${emailError ? 'is-invalid' : ''}`}
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      placeholder="Enter Your Email Address"
                      readOnly
                      required
                    />
                    {emailError && <div className="invalid-feedback">{emailError}</div>}
                  </div>
                  <div className="form-group">
                    <label>Browser </label>
                    <input type="text" className="form-control" value={formData.browser} name="browser" readOnly />
                  </div>
                  <div className="form-group">
                    <label>Browser version number</label>
                    <input type="text" className="form-control" name='browser_version_no' value={formData.browser_version_no} readOnly />
                  </div>

                  <div className="form-group">
                    <label>Operating system </label>
                    <input type="text" className="form-control" name='os' onChange={handleChange} value={formData.os} placeholder="Enter your Operating system "  />
                  </div>
                  <div className="form-group">
                    <label>Version number </label>
                    <input type="text" className="form-control" name='version_number' onChange={handleChange} value={formData.version_number} placeholder="Enter your Version number"/>
                  </div>



                  <div className="form-group">
                    <label>Page with Issue </label>
                    <select className="form-control" onChange={handleChange} value={formData.page_issue} name="page_issue">
                      <option>Select</option>
                      <option value={"Login_issue"} >Login issue</option>
                      <option value={"Home"} >Home</option>
                      <option value={"Reports"}>Reports</option>
                      <option value={"Administrator_Module"}>Administrator Module</option>
                      <option value={"Basic_Info"}>Basic Info</option>
                      <option value={"Emergency_Contact"}>Emergency Contact</option>
                      <option value={"Consent_to_Service"}>Consent to Service</option>
                      <option value={"Authorization_for_ROI"}>Authorization for ROI</option>
                      <option value={"Criminal_Justice_ROI"}>Criminal Justice ROI</option>
                      <option value={"Hospital_ROI"}>Hospital ROI</option>
                      <option value={"Other_Agencies"}>Other Agencies</option>
                      <option value={"Hangout"}>Hangout</option>
                      <option value={"Probation/Parole/Cases"}>Probation/Parole/Cases</option>
                      <option value={" Homelessness"}> Homelessness</option>
                      <option value={"Assessment"}>Assessment</option>
                      <option value={" Supplementary_Information"}> Supplementary Information</option>
                      <option value={"Recovery_Goals"}>Recovery Goals</option>
                      <option value={"Health_Screenings"}>Health Screenings</option>
                      <option value={"Follow_Ups"}>Follow-Ups</option>
                      <option value={"Notes"}>Notes</option>
                      <option value={"Referrals"}>Referrals</option>
                      <option value={"Routings"}>Routings</option>
                      <option value={"Change_Participation"}>Change Participation</option>
                      <option value={"State_Assessment"}> State Assessment</option>
                    </select>
                  </div>
                  <div className="form-group mt-3">
                    <label>Please describe the issue you are having as well as the steps that led to the issue</label>
                    <textarea type="text" className="form-control" name='page_issue_description' onChange={handleChange} value={formData.page_issue_description} placeholder="Enter your Issue" />
                    {issueDescriptionErr != "" ? <span style={{color : "red"}}>{issueDescriptionErr}</span> : ""}
                  </div>
                  <div class="custom-file" >
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={(e) => (handleAttachmentsChange(e))}
                      multiple
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {selectedFiles.length > 0
                        ? `${selectedFiles.length} Files Selected`
                        : 'Choose file'}
                    </label>
                    {/* Display the selected file names (optional) */}
                    {selectedFiles.length > 0 && (
                      <ul>
                        {selectedFiles.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="form-group mt-3">
                    <button type="button" className="btn btn-success float-right " value="save" onClick={handleSave}> <i className="fas fa-save"></i> Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>

    </>
  )
}

export default RequestSupport